<template>
  <div class="product-box__segment-header">
    <component
      :is="personalizedBannerLink ? 'a' : 'div'"
      v-if="productBoxData.personalPreferenceMessageKey && !showReview"
      class="product-box__personalization-banner | personalization__banner"
      :class="`personalization__banner--type_${personalPreferenceRank}`"
      :href="personalizedBannerLink"
    >
      <div class="product-box__personalization-banner-icon | personalization__banner-icon" />
      <div
        class="product-box__personalization-banner-content | personalization__banner-content"
        v-html="$t(productBoxData.personalPreferenceMessageKey)"
      />
    </component>
    <component
      class="product-box__header-container"
      :is="productLink ? 'a' : 'div'"
      :href="productLink"
    >
      <div
        class="product-box__header-text"
        :class="{
          'product-box__header-text--with-vintage': productBoxData.vintage
        }"
      >
        <span class="product-box__name">
          <span
            class="product-box__bottle-amount"
            v-if="numberOfBottlesInSet"
          >
            {{ numberOfBottlesInSet }} x
          </span>
          <span
            v-if="productBoxData.vintage"
            class="product-box__vintage"
          >
            {{ productBoxData.vintage }}
          </span>
          {{ productBoxData.description1 }}
        </span>
        <div
          class="product-box__short-description"
          v-if="productBoxData.description2 || productBoxData.shortDescription"
          v-html="productShortDescription"
        />
        <div
          v-if="showInBasket && productBoxData.inBasket"
        >
          <a
            class="product-box__status product-box__status--basket product-box__status--basket-up"
            :href="basketUrl"
          >
            {{ $t('ads.ads_top_info.article_in_basket') }}
          </a>
        </div>
      </div>

      <span
        v-if="!productBoxData.zeroPriceItem && productBoxData.valid && !productBoxData.recurringOrderFollowUpPacket"
        class="product-box__button-wishlist"
        :class="{
          'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
          'product-box__button-wishlist--remove': !isWishlistEnabled,
          'product-box__button-wishlist--loading': state === 'loadingWishlist',
        }"
        @click.prevent="$emit('wishlistClicked')"
      />
    </component>
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import jsGlobalConfig from '../../../bps/globals/jsGlobalConfig';

  @Options({
    name: 'product-box-segment-header'
  })
  export default class ProductBoxSegmentHeader extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    state: string;
    @Prop()
    numberOfBottlesInSet: number;
    @Prop()
    productLink: string
    @Prop({default: false})
    showReview: boolean
    @Prop({default: false})
    showInBasket: boolean
    @Prop({default: true})
    isWishlistEnabled: boolean;

    basketUrl = jsGlobalConfig().getBasketUrl();

    get productShortDescription() {
      if (this.productBoxData.description2 && this.productBoxData.shortDescription) {
        return `${this.productBoxData.description2}, ${this.productBoxData.shortDescription}`;
      }
      return this.productBoxData.description2 || this.productBoxData.shortDescription;
    }

    get personalizedBannerLink() {
      return this.$t(`productbox.link.${this.productBoxData.personalPreferenceMessageKey}`, [this.productBoxData.url]);
    }

    get personalPreferenceRank() {
      return this.productBoxData.personalPreferenceMessageKey.split('.').pop();
    }
  }
</script>
