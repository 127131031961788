import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.productBoxData.valid)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.productStarHref,
        "aria-label": "product review",
        class: "product-box__segment-review"
      }, [
        _createElementVNode("div", {
          class: "product-box__segment-review__headline",
          innerHTML: _ctx.$t('productbox.product.review.headline')
        }, null, 8 /* PROPS */, _hoisted_2)
      ], 8 /* PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}