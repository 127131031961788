import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_counter_input_haw = _resolveComponent("counter-input-haw")!
  const _component_counter_input_bps = _resolveComponent("counter-input-bps")!

  return (_ctx.variantA)
    ? (_openBlock(), _createBlock(_component_counter_input_haw, _mergeProps({
        key: 0,
        class: "counter--haw"
      }, _ctx.$props, {
        onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event))),
        ref: "counterInputHaw"
      }), null, 16 /* FULL_PROPS */))
    : (_openBlock(), _createBlock(_component_counter_input_bps, _mergeProps({
        key: 1,
        class: "counter--bps"
      }, _ctx.$props, {
        onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event))),
        ref: "counterInputBps"
      }), null, 16 /* FULL_PROPS */))
}