import {
  productBoxWideSettings,
  ProductBoxWideSettings
} from '../../../bps/components/productBoxWide/productBoxWideSettings';

productBoxWideSettings.flags = {
  whitelistImage: ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'free', 'bottleSize', 'new', 'newVintage', 'nonAlcoholic', 'awardInSet', 'award', 'bio', 'vegan'],
  whitelistTag: [],
  whitelistConversion: ['stoerer'],
};
productBoxWideSettings.rating.type = 'text';

export {productBoxWideSettings, ProductBoxWideSettings};
