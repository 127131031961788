import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-name__vintage"
}
const _hoisted_2 = {
  key: 1,
  class: "product-name__descr1"
}
const _hoisted_3 = {
  key: 2,
  class: "product-name__descr2"
}
const _hoisted_4 = {
  key: 1,
  class: "product-name__descr1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productClickable ? 'a' : 'span'), {
    href: _ctx.productClickable ? _ctx.product.url || _ctx.product.productUrl : null,
    class: "product-name"
  }, {
    default: _withCtx(() => [
      (_ctx.product.description1 || _ctx.product.description2)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.product.vintage)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.product.vintage), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.product.description1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.product.description1), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createTextVNode(),
            (_ctx.product.description2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.product.description2), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.product.name), 1 /* TEXT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href"]))
}