import {productBoxSettings, ProductBoxSettings} from '../../../bps/components/productBox/productBoxSettings';

productBoxSettings.price.showTax = true;
productBoxSettings.flags.src.awardInSetImage = '/assets/hawesko/frontend-core/staticImages/awardInSet.svg';
productBoxSettings.flags.whitelist = ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'free', 'bottleSize', 'new', 'newVintage', 'nonAlcoholic', 'awardInSet', 'award', 'bio', 'vegan'];
productBoxSettings.flags.whitelistInSet = ['free', 'bottleSize', 'new', 'newVintage', 'nonAlcoholic', 'award', 'bio', 'vegan'];
productBoxSettings.flags.whitelistTag = ['stoerer'];
productBoxSettings.rating.type = 'text';

export {productBoxSettings, ProductBoxSettings};
