<template>
  <component
    :is="productClickable ? 'a' : 'span'"
    :href="productClickable ? product.url || product.productUrl : null"
    class="product-name"
  >
    <template
      v-if="product.description1 || product.description2"
    >
      <span
        v-if="product.vintage"
        class="product-name__vintage"
      >
        {{ product.vintage }}
      </span>
      <span
        v-if="product.description1"
        class="product-name__descr1"
      >
        {{ product.description1 }}
      </span>
      <div
        v-if="product.description2"
        class="product-name__descr2"
      >
        {{ product.description2 }}
      </div>
    </template>
    <div
      v-else
      class="product-name__descr1"
    >
      {{ product.name }}
    </div>
  </component>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import BasketItem from '../../../bps/types/BasketItem';
  import ProductBoxData from '../../../bps/types/ProductBoxData';
  import Product from '../../../bps/types/Product';
  import type AccountOrderItem from '../../../bps/types/AccountOrderItem';

  @Options({
    name: 'product-name'
  })
  export default class ProductName extends Vue {
    @Prop()
    productClickable: boolean;
    @Prop()
    readonly product: BasketItem | ProductBoxData | Product | AccountOrderItem;
  }
</script>
