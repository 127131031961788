import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-qty-and-cart-btn product-box__buttonss"
}
const _hoisted_2 = { class: "product-box__status product-box__status--unavailable" }
const _hoisted_3 = {
  key: 1,
  class: "product-qty-and-cart-btn product-box__buttons"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 2,
  class: "product-qty-and-cart-btn product-box__buttons--currently-free-product-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_counter_input = _resolveComponent("counter-input")!
  const _directive_t = _resolveDirective("t")!

  return (_ctx.productData.recurringOrderFollowUpPacket)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */), [
          [_directive_t, 'product.button.recurringOrderFollowUpPacket']
        ])
      ]))
    : (!_ctx.productData.currentlyFree)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.productData.soldoutRelation)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.productData.soldoutRelation.type === 'SUCCESSOR' && _ctx.addSuccessorActive)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_counter_input, {
                        class: "product-box__counter",
                        counter: _ctx.productData.counter,
                        onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('quantityChange', $event))),
                        ref: "quantity"
                      }, null, 8 /* PROPS */, ["counter"]),
                      _createTextVNode(),
                      _createElementVNode("button", {
                        "aria-label": "add successor to basket",
                        class: _normalizeClass(["product-box__button-to-cart | button button--successor-to-basket", {
            'button--loading': _ctx.state === 'loadingBasket'
          }]),
                        disabled: _ctx.state === 'loadingBasket',
                        type: "button",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addToBasket', _ctx.$refs.quantity.value)))
                      }, _toDisplayString(_ctx.$t('availability.available.successor.addToBasket')), 11 /* TEXT, CLASS, PROPS */, _hoisted_4)
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: "product-box__status product-box__status--successor",
                      href: _ctx.soldoutRelationUrl
                    }, _toDisplayString(_ctx.$t('ads.ads_top_info.go_to.' + _ctx.productData.soldoutRelation.type)), 9 /* TEXT, PROPS */, _hoisted_5))
              ], 64 /* STABLE_FRAGMENT */))
            : (!_ctx.productData.availability.available && _ctx.productLink)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "product-box__status product-box__status--unavailable",
                  href: _ctx.productLink
                }, _toDisplayString(_ctx.productData.availability.statement), 9 /* TEXT, PROPS */, _hoisted_6))
              : (_ctx.productData.purchaseOnRequestArticle && _ctx.productData.valid)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: "product-box__status product-box__status--on-request",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onRequestClicked', $event)))
                  }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_on_request')), 1 /* TEXT */))
                : (!_ctx.recurringBuyable && _ctx.productData.recurringOrder && _ctx.productLink)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      "aria-label": "Go to product",
                      class: "button product-box__button--to-product",
                      href: _ctx.productLink
                    }, _toDisplayString(_ctx.$t('productbox.abo.product.link')), 9 /* TEXT, PROPS */, _hoisted_7))
                  : (_ctx.productData.valid)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                        _createVNode(_component_counter_input, {
                          class: "product-box__counter",
                          counter: _ctx.productData.counter,
                          onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('quantityChange', $event))),
                          ref: "quantity"
                        }, null, 8 /* PROPS */, ["counter"]),
                        _createTextVNode(),
                        _createElementVNode("button", {
                          "aria-label": "add to basket",
                          class: _normalizeClass(["product-box__button-to-cart | button button--add-to-basket", {
          'button--loading': _ctx.state === 'loadingBasket',
          [_ctx.cartButtonClass]: _ctx.cartButtonClass !== null
        }]),
                          disabled: _ctx.state === 'loadingBasket',
                          type: "button",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('addToBasket', _ctx.$refs.quantity.value)))
                        }, _toDisplayString(_ctx.cartButtonText), 11 /* TEXT, CLASS, PROPS */, _hoisted_8)
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9))
}