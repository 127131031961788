import { vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["type", "min", "max"]
const _hoisted_2 = { class: "counter__input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quantity_box = _resolveComponent("quantity-box")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["counter", {
      'counter--disabled' : _ctx.disableInputEdit
    }])
  }, [
    (_ctx.isInputEnabled)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          type: _ctx.inputType,
          inputmode: "numeric",
          class: "counter__input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          min: _ctx.minQuantity,
          max: _ctx.maxQuantity,
          ref: "input",
          autocomplete: "off",
          autocorrect: "off",
          onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyUpHandler && _ctx.keyUpHandler(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
          tabindex: "-1"
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)), [
          [_vModelDynamic, _ctx.value]
        ])
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["counter__input notranslate", {
        'counter__input--disabled' : _ctx.disableInputEdit
      }]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.value), 1 /* TEXT */)
        ], 2 /* CLASS */)),
    _createTextVNode(),
    _createVNode(_Transition, { name: "vue-animation--spin-fade" }, {
      default: _withCtx(() => [
        (_ctx.isQuantityBoxVisible)
          ? _withDirectives((_openBlock(), _createBlock(_component_quantity_box, {
              key: 0,
              "max-quantity": _ctx.maxQuantity,
              "min-quantity": _ctx.minQuantity,
              "current-quantity": _ctx.value,
              "trade-quantity": _ctx.tradeQuantity,
              onChange: _ctx.changeQuantity,
              onOtherQuantityClick: _ctx.enableInputField
            }, null, 8 /* PROPS */, ["max-quantity", "min-quantity", "current-quantity", "trade-quantity", "onChange", "onOtherQuantityClick"])), [
              [_directive_outside_click, _ctx.toggleQuantityBox, true]
            ])
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}