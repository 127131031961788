<template>
  <counter-input-haw
    v-if="variantA"
    class="counter--haw"
    v-bind="$props"
    @update="$emit('change', $event)"
    ref="counterInputHaw"
  />
  <counter-input-bps
    v-else
    class="counter--bps"
    v-bind="$props"
    @update="$emit('change', $event)"
    ref="counterInputBps"
  />
</template>

<script lang="ts">
  import {defineComponent, PropType} from 'vue';
  import CounterInputHaw from './counterInput.vue';
  import CounterInputBps from '../../../bps/components/counterInput/counterInput.vue';
  import Counter from '../../../bps/types/Counter';
  import jsGlobalConfig from '../../../bps/globals/jsGlobalConfig';

  export default defineComponent({
    name: 'CounterInputAb',
    components: {
      CounterInputHaw, CounterInputBps
    },

    props: {
      counter: {
        type: Object as PropType<Counter>,
        required: true
      },
      onlyOneItem: {
        type: Boolean as PropType<boolean>,
        default: false
      },
      disableInputEdit: {
        type: Boolean as PropType<boolean>,
        default: false
      }
    },

    computed: {
      variantA() {
        return jsGlobalConfig().getAbVariants().counter === 'variant-a';
      },
      value() {
        return this.counterInput.value;
      },
      counterInput() {
        return this.$refs.counterInputHaw || this.$refs.counterInputBps;
      }
    },
    methods: {
      setValue(value: number) {
        this.counterInput.setValue(value);
      }
    }
  });
</script>
