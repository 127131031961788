<template>
  <div class="product-box-wide__segment-center">
    <a
      class="product-box-wide__header product-box__segment-header"
      :href="productBoxData.url"
    >
      <div class="product-box-wide__header-text product-box__header-text">
        <span
          v-if="productBoxData.vintage"
          class="product-box-wide__vintage product-box__vintage"
        >
          {{ productBoxData.vintage }}
        </span>
        <span class="product-box-wide__name product-box__name">
          {{ productBoxData.description1 }}
        </span>
        <span
          v-if="productBoxData.description2"
          class="product-box-wide__sub-name product-box__name"
        >
          {{ productBoxData.description2 }}
        </span>
        <span
          v-if="productBoxData.description3"
          class="product-box-wide__sub-name product-box__name"
        >
          {{ productBoxData.description3 }}
        </span>
        <span
          class="product-box-wide__short-description product-box__short-description"
          v-if="productBoxData.shortDescription"
          v-html="productBoxData.shortDescription"
        />
      </div>
    </a>
    <div
      class="product-box-wide__long-description"
      v-html="descriptionExcerpt"
    />
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';

  @Options({
    name: 'product-box-wide-segment-center'
  })
  export default class ProductBoxWideSegmentCenter extends Vue {
    @Prop()
    descriptionExcerpt: String;
    @Prop()
    minimumRating: String;
    @Prop()
    basketUrl: String;
    @Prop()
    productBoxData: ProductBoxData;
  }
</script>
