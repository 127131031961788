<template>
  <a
    v-if="productBoxData.valid"
    :href="productStarHref"
    aria-label="product review"
    class="product-box__segment-review"
  >
    <div
      class="product-box__segment-review__headline"
      v-html="$t('productbox.product.review.headline')"
    />
  </a>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';

  @Options({
    name: 'product-box-segment-review'
  })
  export default class ProductBoxSegmentReview extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop({default: 5})
    initialRating: number;
    @Prop()
    productLink: string;

    get productStarHref() {
      return this.productLink;
    }
  }
</script>
