import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "quantity-box counter__quantity-box" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableQuantities, (quantity) => {
      return (_openBlock(), _createElementBlock("li", {
        key: quantity,
        class: _normalizeClass(["quantity-box__item", {
        'quantity-box__item--current' : quantity === _ctx.currentQuantity,
        'quantity-box__item--disabled' : !_ctx.isQuantityEnabled(quantity),
      }]),
        onClick: ($event: any) => (_ctx.handleQuantityClick(quantity))
      }, _toDisplayString(quantity), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */)),
    _createTextVNode(),
    _createElementVNode("li", {
      class: "quantity-box__item quantity-box__item--other",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('otherQuantityClick')))
    }, _toDisplayString(_ctx.$t('quantityBox.other')), 1 /* TEXT */)
  ]))
}